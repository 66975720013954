.includes-mark {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.includes-icon {
  display: flex;
  padding: 5px;
  align-items: center;
  border-radius: 50%;
  flex-direction: row;
  justify-content: center;
  background-color: #414141;
}
.includes-icon1 {
  fill: #C6FF4B;
  width: 12px;
  height: 12px;
}
.includes-label {
  color: #C2C6CC;
  font-size: 14px;
  font-family: Poppins;
  line-height: 25px;
}










































































