.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  /* background-image: url('/BackGround/background-puzzle.jpg'); */
  background-color: #1b1a1a;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.colorful-text {
  background: -webkit-linear-gradient(#FFF01E, #00FF82);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 400px;
  height: 64px;
  text-align: center;
  vertical-align: bottom;
  font-style: normal;
  font-weight: 500;
  font-family: Poppins;
  font-size: 64px;
  line-height: 64px;
  color: rgb(255, 255, 255);
  background-image: -webkit-linear-gradient(top, #FFF01E, #00FF82);
}

.colorful-text2 {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  font-weight: 600;
  font-family: Poppins;
  font-size: 16px;
  color: rgb(255, 255, 255);
  background-image: -webkit-linear-gradient(left, rgb(255, 251, 0), rgb(65, 204, 0));
}

.home-branding-logo {
  width: 400px;
  height: 400px;
  box-sizing: content-box;
}

button.buyBtn {
  background-color: #aeff00;
  color: #000000;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  border-radius: 20px;
  padding: 20px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  bottom: 0 !important;
  margin-bottom: 40px !important;
  margin-top: 50px;
}

button.buyBtn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button.buyBtn span:after {
  content: '\00bb';
  position: relative;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

button.buyBtn:hover span {
  padding-right: 25px;
}

button.buyBtn:hover span:after {
  opacity: 1;
  right: 0;
}

button.primary {
  position: absolute;
  bottom: 10px;
  background-color: #aeff00;
  color: rgb(0, 0, 0);
  cursor: pointer;
  display: flex;
  padding: 15px 45px;
  text-align: center;
  align-items: center;
  padding-top: 24px;
  transition: 0.5s;
  padding-left: 32px;
  border-radius: 10px;
  padding-right: 32px;
  flex-direction: column;
  padding-bottom: 24px;
  text-transform: uppercase;
  background-size: 200% auto;
  background-image: linear-gradient(to right, #00FF82 0%, #FFF01E 51%, #00FF82 100%);
  font-weight: 700;
}

button.primary:hover {
  color: #fff;
  opacity: 0.5;
  text-decoration: none;
  background-position: right center;
}

button.primary span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

button.primary span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

button.primary:hover span {
  padding-right: 25px;
}

button.primary:hover span:after {
  opacity: 1;
  right: 0;
}

.section-head {
  font-size: 38px;
  padding-top: 5vh;
  font-weight: 600;
}

.home-section {
  height: 100vh;
  display: flex;
  align-items: center;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}

.team-about {
  font-size: 30px;
  text-align: center;
  line-height: 30px;
}

.team {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.team-2 {
  margin-top: 7vh;
  margin-bottom: 13vh;
}

.team-left-column {
  flex: 3;
  font-size: 26px;
  line-height: 30px;
}

.team-right-column {
  flex: 1;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
}

.right-column img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.tipprofesszor-logo {
  margin-top: 8vh;
}

.tippdoktor-logo {
  margin-top: 0;
}

.why-us-header {
  color: #E4E40C;
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
  text-align: center;
}

.why-us-text {
  font-size: 26px;
  line-height: 30px;
}

.why-us-text2 {
  font-size: 26px;
  line-height: 40px;
}

.home-hero {
  width: 100%;
  height: auto;
  display: flex;
  /* max-width: 1200px; */
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.home-main {
  gap: var(--dl-space-space-threeunits);
  width: 664px;
  height: 500;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-header {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-heading {
  color: rgb(255, 255, 255);
  width: 718px;
  font-size: 64px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  align-items: center;
  text-align: center;
}

.home-text003 {
  color: rgb(255, 255, 255);
  width: 400px;
  height: 63px;
  font-size: 64px;
  background: -webkit-linear-gradient(yellow, green);
  font-style: normal;
  text-align: center;
  font-family: Poppins;
  font-weight: 500;
  line-height: 50px;
  vertical-align: middle;
  background-image: -webkit-linear-gradient(top, yellow, green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-caption {
  color: rgb(255, 255, 255);
  width: 700;
  font-size: 20px;
  font-family: Poppins;
  line-height: 30px;
  text-align: center;
}

.home-buttons {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.home-link {
  display: contents;
}

.home-text004 {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-weight: 700;
}

.home-link1 {
  display: contents;
}

.home-get-started1 {}

.home-text005 {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  font-weight: 700;
}

.home-image {
  width: 800px;
  height: 400px;
  margin: auto;
  position: relative;
  align-content: center;
  flex-direction: column;
  padding-left: 2vh;
}

.home-section01 {
  flex: 1;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-bottom-width: 1px;
  border-top-width: 1px;
  margin: auto;
}

.home-text008 {
  color: #E4E40C;
  font-size: 40px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-paragraph {
  color: rgb(204, 204, 204);
  width: 1190px;
  /* height: auto; */
  font-size: 24;
  align-self: center;
  text-align: left;
  font-family: Poppins;
  line-height: 28px;
  padding-top: 5vh;
}

.home-text016 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}

.home-text017 {
  line-height: 36px;
  text-transform: capitalize;
}

.home-text018 {
  color: #c6ff4b;
  font-weight: 700;
}

.home-text019 {
  color: #c6ff4b;
  font-family: Poppins;
  font-weight: 700;
  line-height: 28px;
}

.home-text020 {
  line-height: 36px;
  text-transform: capitalize;
}

.home-text033 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}

.home-text034 {
  line-height: 36px;
  text-transform: capitalize;
}

.home-section02 {
  gap: var(--dl-space-space-threeunits);
  padding-top: 120px;
  border-color: #51515A;
  padding-bottom: 120px;
}

.home-container1 {
  width: 800px;
  height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text036 {
  color: rgb(198, 255, 75);
  height: 120px;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.home-text039 {
  color: rgb(255, 255, 255);
  font-size: 22px;
  align-self: center;
  margin-top: 30px;
  text-align: center;
}

.home-container2 {
  width: 800px;
  height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text040 {
  color: rgb(198, 255, 75);
  height: 120px;
  font-size: 40px;
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
}

.home-text043 {
  color: rgb(255, 255, 255);
  font-size: 22px;
  margin-top: 30;
  text-align: center;
}

.home-container3 {
  width: 800px;
  height: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.home-text044 {
  color: rgb(198, 255, 75);
  height: 120px;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.home-text045 {
  font-size: 40px;
}

.home-text047 {
  color: rgb(255, 255, 255);
  font-size: 21px;
  margin-top: 30;
  text-align: center;
}

.home-section03 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  padding-top: 120px;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #292929;
}

.home-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-header01 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-self: center;
  align-items: flex-start;
  padding-top: 120px;
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: flex-start;
}

.home-heading01 {
  text-align: left;
}

.home-buttons1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-pasted-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.home-text051 {
  color: #000000;
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 700;
  line-height: 24px;
}

.home-image1 {
  top: -180px;
  right: 0px;
  width: 470px;
  height: 600px;
  z-index: 100;
  position: absolute;
  object-fit: cover;
}

.home-section04 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-bottom-width: 1px;
}

.home-pricing {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
}

.home-header02 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-right {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph1 {
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  line-height: 32px;
}

.home-container4 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.home-plans-container {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-switch {
  gap: var(--dl-space-space-unit);
  display: none;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  padding-bottom: 8px;
  justify-content: center;
  background-color: #292929;
}

.home-text052 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-switch2 {
  padding-top: var(--dl-space-space-unit);
  background-color: #80FF44;
}

.home-text053 {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-plans {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
}

.home-plan {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  height: 80vh;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #292929;
  align-items: center;
  border: 2px solid transparent;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.5s ease;
}

.home-plan:hover {
  border: 2px solid gold;
  box-shadow: 0 0 10px gold;
  transition: border 0.5s, box-shadow 0.5s;
}

.home-plan0 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #292929;
  align-items: center;
  border: 2px solid transparent;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.5s ease;
  height: 95vh;
}

.home-plan0:hover {
  border: 2px solid gold;
  box-shadow: 0 0 10px gold;
  transition: border 0.5s, box-shadow 0.5s;
}

.home-details {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-header03 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-name {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 36px;
  text-align: center;
}

.home-header-image {
  margin-top: 5vh;
}

.home-buy-details {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-features {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading03 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-list {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-buy {
  width: 100%;
  display: flex;
  background-color: #80FF44;
}

.home-text054 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text055 {
  font-size: 24px;
}

.home-plan1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #292929;
  border: 2px solid transparent;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 0.3s ease;
  height: 95vh;
}

.home-plan1:hover {
  border: 2px solid rgb(184, 182, 172);
  box-shadow: 0 0 10px rgb(184, 182, 172);
  transition: border 0.5s, box-shadow 0.5s;
}

.home-details01 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-header04 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-name1 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 36px;
}

.home-buy-details1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-features1 {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading04 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-list1 {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-buy1 {
  width: 100%;
  display: flex;
  background-color: #80FF44;
}

.home-text057 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text058 {
  font-size: 24px;
}

.home-plan2 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #292929;
  box-shadow: 0 0 0 transparent;
  border: 2px solid #292929;
  background:
    linear-gradient(#292929, #292929) padding-box,
    linear-gradient(90deg, #16A085 0%, #F4D03F 50%, #F4D03F 100%) border-box;
  transition: box-shadow 0.3s ease;
  height: 95vh;
}


.home-plan2:hover {
  border: 2px solid #0000;
  box-shadow: 0 0 10px rgb(255, 213, 0);
  transition: border 0.5s, box-shadow 0.5s;
}

.home-details02 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-header05 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-name2 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 36px;
}

.home-buy-details2 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-features2 {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading05 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-list2 {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-buy2 {
  width: 100%;
  display: flex;
  margin-top: 92;
  background-color: #80FF44;
}

.home-text060 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text061 {
  font-size: 24px;
}

.home-plans1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
}

.home-plan3 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  height: 50vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  background-color: #292929;
  background-color: #292929;
  box-shadow: 0 0 0 transparent;
  border: 2px solid #292929;
  background:
    linear-gradient(#292929, #292929) padding-box,
    linear-gradient(90deg, #16A085 0%, #F4D03F 50%, #F4D03F 100%) border-box;
  transition: box-shadow 0.3s ease;
}

.home-plan3:hover {
  border: 2px solid #0000;
  box-shadow: 0 0 10px rgb(238, 198, 0);
  transition: border 0.5s, box-shadow 0.5s;
}

.home-details03 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-header06 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-name3 {
  color: rgb(255, 255, 255);
  font-size: 30;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 36px;
}

.home-buy-details3 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-features3 {
  gap: var(--dl-space-space-unit);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading06 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-list3 {
  gap: 1vh;
  height: 135px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-buy3 {
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 150;
  padding-right: var(--dl-space-space-oneandhalfunits);
  background-color: #80FF44;
}

.home-text063 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text064 {
  font-size: 24px;
}

.home-plan4 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  height: 50vh;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  position: relative;
  background-color: #292929;
  box-shadow: 0 0 0 transparent;
  border: 2px solid #292929;
  background:
    linear-gradient(#292929, #292929) padding-box,
    linear-gradient(90deg, #16A085 0%, #F4D03F 50%, #F4D03F 100%) border-box;
  transition: box-shadow 0.3s ease;
}

.home-plan4:hover {
  border: 2px solid #0000;
  box-shadow: 0 0 10px rgb(255, 213, 0);
  transition: border 0.5s, box-shadow 0.5s;
}

.home-details04 {
  gap: var(--dl-space-space-twounits);
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-header07 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-name4 {
  color: rgb(255, 255, 255);
  font-size: 30;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 36px;
}

.home-buy-details4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-features4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading07 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-list4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-buy4 {
  width: 100%;
  display: flex;
  margin-top: 220;
  background-color: #80FF44;
}

.home-text066 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text067 {
  font-size: 24px;
}

.home-plan {
  gap: 2vh;
  flex: 1;
  height: 90vh;
  display: flex;
  align-items: center;
  padding-top: 5vh;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 20px;
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: flex-start;
  position: relative;
  background-color: #292929;
  box-shadow: 0 0 0 transparent;
  border: 2px solid #292929;
  background:
    linear-gradient(#292929, #292929) padding-box,
    linear-gradient(90deg, #16A085 0%, #F4D03F 50%, #F4D03F 100%) border-box;
  transition: box-shadow 0.3s ease;
}

.home-plan:hover {
  border: 2px solid #0000;
  box-shadow: 0 0 10px rgb(255, 213, 0);
  transition: border 0.5s, box-shadow 0.5s;
}

.home-details05 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-header08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-name5 {
  color: rgb(255, 255, 255);
  font-size: 30;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 36px;
}

.home-buy-details5 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-features5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading08 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-list5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-buy5 {
  width: 100%;
  display: flex;
  margin-top: 220;
  background-color: #80FF44;
}

.home-text069 {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text070 {
  font-size: 24px;
}

.home-help {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.home-text072 {
  color: rgb(255, 255, 255);
  font-family: Poppins;
  line-height: 24px;
}

.home-contact-support {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #80FF44;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}

.home-contact-support:hover {
  opacity: 0.5;
}

.home-text075 {
  color: rgb(128, 255, 68);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-section06 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-bottom-width: 1px;
}

.home-section07 {
  gap: var(--dl-space-space-sixunits);
  width: 100%;
  height: 110vh;
  display: flex;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-bottom-width: 1px;
}

.home-note {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}

.home-image2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-image3 {
  width: 50%;
  height: 50%;
  object-fit: cover;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
}

.home-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-heading09 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-heading10 {
  color: #80FF44;
  width: 605px;
  align-self: flex-end;
}

.home-checkmarks {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-section08 {
  flex: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  padding-top: 110px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 111px;
  justify-content: center;
  border-bottom-width: 1px;
}

.home-text076 {
  color: rgb(255, 255, 255);
  width: 1154px;
  height: 46px;
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-paragraph2 {
  color: rgb(204, 204, 204);
  width: 1149px;
  height: var(--dl-size-size-xxlarge);
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
  margin-bottom: var(--dl-space-space-twounits);
}

.home-text079 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text083 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text087 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text091 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text096 {
  color: rgb(255, 255, 255);
  width: 1152px;
  height: 50px;
  font-size: 40px;
  align-self: center;
  font-style: normal;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-threeunits);
}

.home-paragraph3 {
  color: rgb(204, 204, 204);
  width: 1147px;
  height: 347px;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text099 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text102 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text105 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text108 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text111 {
  color: #cccccc;
  font-size: 22px;
  font-family: Poppins;
  line-height: 28px;
}

.home-text113 {
  color: var(--dl-color-danger-500);
  font-style: normal;
  font-weight: 700;
}

.home-text114 {
  color: var(--dl-color-danger-500);
  font-size: 22px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 700;
  line-height: 28px;
}

.home-section09 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}

.home-header10 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.home-left1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-right1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph4 {
  width: 100%;
  max-width: 480px;
}

.home-cards {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-section11 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}

.home-note1 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: center;
}

.home-image4 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-image5 {
  object-fit: cover;
}

.home-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-caption1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-section13 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}

.home-cube {
  top: 20px;
  left: 0px;
  width: 120px;
  height: 120px;
  display: flex;
  position: absolute;
  transform: rotateX(240deg) rotateY(25deg) rotateZ(-110deg);
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform-style: preserve-3d;
}

.home-top {
  transform: translateZ(-120px);
  background-image: linear-gradient(270deg, rgb(253, 253, 253) 0.00%, rgb(178, 178, 178) 100.00%);
}

.home-front {
  transform: rotateX(90deg);
  background: grey;
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%, rgb(203, 203, 203) 100.00%);
  transform-origin: bottom;
}

.home-left2 {
  width: 120px;
  transform: translateZ(-120px) rotateY(90deg);
  background-image: linear-gradient(90deg, rgb(247, 247, 247) 0.00%, rgb(203, 203, 203) 100.00%);
  transform-origin: right;
}

.home-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  background-color: #292929;
}

.home-header12 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-description {
  font-size: 20px;
  line-height: 30px;
}

.home-buttons2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-get-started2 {
  display: flex;
  background-color: #80FF44;
}

.home-text117 {
  color: #0C100C;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
}

.home-book-demo {
  display: flex;
  background-color: #ffffff;
}

.home-text118 {
  color: rgb(12, 16, 12);
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
}

.home-section14 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}

.home-header13 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-left3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading15 {
  text-align: center;
}

.home-right2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-paragraph5 {
  width: 100%;
  max-width: 600px;
  text-align: center;
}

.home-cards1 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-threeunits);
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto auto;
}

.home-container5 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}

.home-container6 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}

.home-container7 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}

.home-view-more {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: none;
  transition: 0.3s;
  align-items: center;
  border-color: #80FF44;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}

.home-view-more:hover {
  opacity: 0.5;
}

.home-text119 {
  color: rgb(128, 255, 68);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.home-section16 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  padding-top: 120px;
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-top-width: 1px;
}

.home-header14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading16 {
  text-align: center;
}

.home-cards2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-section18 {
  gap: var(--dl-space-space-fiveunits);
  flex: 2;
  width: 100%;
  height: 120vh;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #51515A;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 50px;
  justify-content: center;
  border-top-width: 1px;



}

.home-header15 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading17 {
  text-align: center;
}

.home-accordion {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.home-accordion1 {
  width: 100%;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-element {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details06 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text120 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text124 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 24px;
  user-select: text;
}

.home-icons {
  position: relative;
}

.home-icon {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element1 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details07 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text128 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text131 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon02 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element2 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details08 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text135 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text138 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon04 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element3 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details09 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text142 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text143 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon06 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element4 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details10 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text144 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text146 {
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text148 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon08 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element5 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details11 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text149 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text152 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon10 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element6 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text155 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text158 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon12 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element7 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details13 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text161 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text162 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon14 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-element8 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-details14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-text163 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}

.home-text164 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}

.home-icon16 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.home-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}

.home-content3 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.home-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-content4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.home-main2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-branding {
  width: 120px;
  object-fit: cover;
}

.home-text165 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 14px;
  max-width: 260px;
  font-family: Poppins;
  line-height: 21px;
}

.home-socials {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}

.home-link2 {
  display: contents;
}

.home-link3 {
  display: contents;
}

.home-link4 {
  display: contents;
}

.home-categories {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: none;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}

.home-category {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-category1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 175px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-header18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.home-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-icon18 {
  width: 24px;
  height: 24px;
}

.home-copyright {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text179 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}

.home-subscribe {
  gap: var(--dl-space-space-unit);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-main3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}

.home-heading18 {
  color: rgb(255, 255, 255);
  font-size: 20px;
  max-width: 275px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  line-height: 30px;
}

.home-input-field {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding: var(--dl-space-space-halfunit);
  align-items: center;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  background-color: #292929;
}

.home-textinput {
  flex: 1;
  color: #ffffff;
  height: 24px;
  line-height: 24px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  outline-style: none;
  background-color: rgba(217, 217, 217, 0);
}

.home-buy6 {
  display: flex;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 40px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: #80FF44;
}

.home-text180 {
  color: rgb(12, 16, 12);
  display: none;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-text181 {
  color: rgb(0, 0, 0);
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 24px;
}

.home-notice {
  color: rgb(104, 104, 104);
  font-size: 14px;
  max-width: 400px;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  line-height: 21px;
}

.home-copyright1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-text184 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}

.home-slide-to-top {
  z-index: 1000;
  align-self: flex-end;
  box-shadow: 5px 5px 10px 0px #FFF01E;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  cursor: pointer;
}

.home-slide-to-top.visible {
  display: block;
  opacity: 1;
}

.home-link5 {
  display: contents;
}

.home-icon20 {
  right: 50px;
  width: 70px;
  bottom: 62px;
  height: 66px;
  position: fixed;
  padding: var(--dl-space-space-halfunit);
  align-self: center;
  opacity: 0.5;
  transition: opacity 0.3 ease-in-out;
  border-radius: var(--dl-radius-radius-round);
  background: #FFF01E;
}

/*Dialog Styles*/
dialog {
  padding: 1rem 3rem;
  background: rgba(255, 255, 255, 0.486);
  max-width: auto;
  padding-top: 2rem;
  border-radius: 20px;
  border: 0;
  /* box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.1); */
  animation: fadeIn 1s ease both;
}

dialog::backdrop {
  animation: fadeIn 1s ease both;
  background: rgba(255, 255, 255, 0.021);
  z-index: 2;
  backdrop-filter: blur(20px);
}

dialog .x {
  filter: grayscale(1);
  border: none;
  background: none;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: ease filter, transform 0.3s;
  cursor: pointer;
  transform-origin: center;
}

dialog .x:hover {
  filter: grayscale(0);
  transform: scale(1.1);
}

dialog h2 {
  font-weight: 600;
  font-size: 2rem;
  padding-bottom: 1rem;
}

dialog p {
  font-size: 1rem;
  line-height: 1.3rem;
  padding: 0.5rem 0;
}

dialog p a:visited {
  color: rgb(var(--vs-primary));
}

@media(max-width: 1600px) {
  .home-heading {
    width: 670px;
    text-align: center;
  }

  .home-section {
    display: flex;
    align-items: center;
    border-color: #51515A;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }

  .home-text003 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }

  .home-image {
    height: 369px;
  }

  .home-plan {
    width: auto;
  }

  .home-plan1 {
    width: auto;
  }

  .home-footer {
    box-shadow: 5px 5px 10px 0px #d4d4d4;
  }

  .home-slide-to-top {
    z-index: 1000;
    align-self: flex-end;
    box-shadow: 5px 5px 10px 0px #cde890;

  }

  .home-icon20 {
    width: 70px;
    bottom: 62px;
    height: 66px;
    padding: var(--dl-space-space-halfunit);
    align-self: center;
    border-radius: var(--dl-radius-radius-round);
    background: #cde890;
  }
}

@media(max-width: 1400px) {

  .home-image {
    height: 320px;
    width: 500px;
  }

  .home-section {
    height: 70vh;
    display: flex;
    align-items: center;
    border-color: #51515A;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
  }
}

@media(max-width: 1200px) {

  .why-us {
    margin: 5vh;
  }

  .home-hero {
    width: 670;
    height: 100vh;
  }

  .home-section {
    height: 80vh;
    display: flex;
    align-items: center;
    border-color: #51515A;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
    justify-content: center;
  }

  .navbar-navbar {
    margin-bottom: 0;
  }

  .home-branding-logo {
    width: 400px;
    height: 400px;
    box-sizing: content-box;
  }

  .home-heading {
    text-align: center;
    width: 664px;
    font-size: 46px;
  }

  .home-main {
    align-items: center;
    width: 600px;
  }

  .home-caption {
    width: 650px;
    text-align: center;
    font-size: 20px;
    max-width: 550px;
  }

  .home-paragraph {
    padding: 30;
  }

  .home-image1 {
    position: relative;
    top: 0;
  }

  .home-image {
    width: 500px;
    height: 300px;
    padding-right: 2vh;
  }

  .home-text076 {
    width: 90%;
  }

  .home-paragraph2 {
    width: 80%;
  }

  .home-text096 {
    width: 90%;
    margin-bottom: var(--dl-space-space-sixunits);
  }

  .home-paragraph3 {
    width: 80%;
  }

  .home-text120 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 28px;
  }

  .home-text122 {
    color: #ffffff;
    font-size: 20px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 28px;
  }

  .home-text124 {
    color: rgb(204, 204, 204);
    font-family: Poppins;
    line-height: 24px;
  }

  .home-text131 {
    color: rgb(204, 204, 204);
    font-family: Poppins;
    line-height: 28px;
  }

  .team-about {
    margin-left: 2vh;
    margin-right: 2vh;
  }

  .team-2 {
    margin-bottom: 15vh;
  }

  .team-left-column {
    margin-left: 5vh;
  }

  .tippdoktor-logo {
    margin-top: 2vh;
  }
}

@media(max-width: 1150px) {

  .why-us {
    margin: 10vh;
  }

  .team-about {
    margin-left: 10vh;
    margin-right: 10vh;
  }

  .team-left-column {
    margin-left: 10vh;
  }

  .team-right-column {
    margin-right: 5vh;
  }

  .tipprofesszor-logo {
    margin-top: 11vh;
  }

  .tippdoktor-logo {
    margin-top: 5vh;
  }

}

@media(max-width: 1100px) {

  .home-section {
    height: 100%;
  }

  .home-hero {
    flex-direction: column;
    padding-top: 15vh;
  }

  .home-image {
    width: 100%;
  }

  .home-heading {
    font-size: 64px;
  }

  .home-caption {
    font-size: 24px;
  }

}

@media(max-width: 991px) {

  .home-paragraph2 {
    margin-bottom: 0;
  }

  .home-text076 {
    margin-bottom: 10vh;
  }

  .home-text096 {
    margin-top: 10vh;
  }

  .why-us-text .why-us-text2 {
    font-size: 16px;
  }

  .home-section {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  .home-hero {
    /* gap: var(--dl-space-space-fourunits);
    flex: 1;
    position: relative;
    max-width: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center; */
  }

  .home-content {
    position: relative;
    align-items: center;
  }

  .home-main {
    align-items: center;
  }

  .home-heading {
    text-align: center;
  }

  .home-caption {
    text-align: center;
  }

  .home-get-started1 {
    flex: 1;
  }

  .home-image {
    width: auto;
    height: auto;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }

  .home-section01 {
    padding-bottom: 120;
  }

  .home-text008 {
    text-align: center;
  }

  .home-paragraph {
    width: 800px;
    height: auto;
    padding: 0;
  }

  .home-text036 {
    color: rgb(198, 255, 75);
    font-size: 30px;
  }

  .home-text037 {
    font-size: 40px;
  }

  .home-text040 {
    font: 30px;
  }

  .home-text044 {
    font-size: 30px;
  }

  .home-content1 {
    width: 1000px;
    align-items: center;
    flex-direction: column;
  }

  .home-header01 {
    height: 272px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .home-buttons1 {
    width: 100%;
  }

  .home-image1 {
    width: 267px;
    height: 385px;
    display: none;
    position: static;
    align-self: flex-end;
    object-fit: none;
  }

  .home-image {
    width: 600px;
  }

  .home-plans {
    flex-direction: column;
  }


  .home-plans1 {
    flex-direction: column;
  }

  .home-image2 {
    flex: 0;
  }

  .home-text076 {
    text-align: center;
  }

  .home-paragraph2 {
    width: 900;
    margin-left: 0;
  }

  .home-text096 {
    text-align: center;
  }

  .home-paragraph3 {
    width: 900;
    margin-left: 0;
  }

  .home-section09 {
    gap: var(--dl-space-space-threeunits);
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .home-header10 {
    gap: var(--dl-space-space-unit);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .home-cards {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }

  .home-section11 {
    padding-top: 0px;
  }

  .home-note1 {
    flex-direction: column-reverse;
  }

  .home-banner {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }

  .home-buttons2 {
    width: 100%;
    flex-direction: column;
  }

  .home-get-started2 {
    width: 100%;
  }

  .home-book-demo {
    width: 100%;
  }

  .home-section14 {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-cards1 {
    grid-template-columns: auto;
  }

  .home-container7 {
    display: none;
  }

  .home-view-more {
    display: flex;
    border-color: #CCCCCC;
  }

  .home-text119 {
    color: #CCCCCC;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .home-cards2 {
    gap: var(--dl-space-space-halfunit);
  }

  .home-content3 {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .home-copyright {
    display: none;
  }

  .home-copyright1 {
    display: flex;
  }

  .home-section08 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .team-left-column {
    font-size: 16px;
  }

  .tipprofesszor-logo {
    width: 250px;
    height: 250px;
    margin-top: 7vh;
  }

  .tippdoktor-logo {
    width: 250px;
    height: 250px;
    margin-top: 0;
  }

  .team-2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .team-3 {
    margin-top: 2vh;
  }
}

@media(max-width: 767px) {

  .home-buy-details3 {
    height: 0;
  }

  .home-features {
    margin-bottom: 40px;
  }

  .home-features1 {
    margin-bottom: 40px;
  }

  .home-features2 {
    margin-bottom: 40px;
  }

  .home-features4 {
    margin-bottom: 40px;
  }

  .home-features5 {
    margin-bottom: 40px;
  }

  .home-hero {
    padding-bottom: 30;
  }

  .home-content {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }

  .home-caption {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
    text-align: center;
  }

  .home-section01 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .home-text008 {
    font-size: 32px;
    text-align: center;
  }

  .home-paragraph {
    width: 660;
    height: auto;
    line-height: 24px;
  }

  .home-text036 {
    font-size: 26;
    text-align: center;
  }

  .home-text039 {
    font-size: 24;
  }

  .home-text040 {
    color: rgb(198, 255, 75);
    font-size: 26;
  }

  .home-text041 {
    font-size: 40px;
  }

  .home-text043 {
    font-size: 24;
  }

  .home-text044 {
    font-size: 26;
  }

  .home-text047 {
    font-size: 24;
  }

  .home-section03 {
    padding-top: var(--dl-space-space-fourunits);
  }

  .home-buttons1 {
    flex-direction: column;
  }

  .home-image1 {
    align-self: center;
  }

  .home-plans {
    flex-direction: column;
  }

  .home-plan {
    width: 100%;

  }

  .home-plan1 {
    width: 100%;

  }

  .home-plan2 {
    width: 100%;
    height: 100%;

  }

  .home-plans1 {
    flex-direction: column;
  }

  .home-plan3 {
    width: 100%;
    height: 100%;

  }

  .home-buy3 {
    height: 75px;
  }

  .home-plan4 {
    width: 100%;
    height: 100%;

  }

  .home-plan {
    width: 100%;
    height: 100%;

  }

  .home-help {
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .home-section07 {
    gap: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .home-note {
    flex-direction: column-reverse;
  }

  .home-image3 {
    display: none;
  }

  .home-section08 {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .home-text076 {
    font-size: 32px;
    text-align: center;
  }

  .home-paragraph2 {
    width: 100%;
    font-size: 20px;
    line-height: 24px;
  }

  .home-text096 {
    font-size: 32px;
    text-align: center;
  }

  .home-paragraph3 {
    width: 100%;
    font-size: 20px;
    line-height: 24px;
  }

  .home-left1 {
    gap: var(--dl-space-space-unit);
  }

  .home-content2 {
    width: 100%;
  }

  .home-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .home-section14 {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }

  .home-cards2 {
    gap: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }

  .home-text120 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text121 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text123 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text128 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text135 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text142 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text144 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text145 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text147 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text149 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text155 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text161 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-text163 {
    font-size: 16px;
    line-height: 24px;
  }

  .home-content4 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }

  .home-main2 {
    gap: var(--dl-space-space-twounits);
  }

  .home-categories {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }

  .home-text181 {
    color: rgb(12, 16, 12);
    font-size: 16px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }

  .team-3 {
    margin-top: 7vh;
  }

  .tippdoktor-logo {
    margin-top: 4vh;
  }
}

@media(max-width: 700px) {

  .home-heading {
    font-size: 50px;
    text-align: center;
  }

  .home-caption {
    width: 500px;
    text-align: center;
    font-size: 20px;
  }

  .home-image {
    height: auto;
  }

  .home-main {
    max-width: 500px;
  }

  .tippdoktor-logo {
    margin-top: 4vh;
  }


}

@media(max-width: 630px) {

  .team-left-column {
    margin-left: 12vh;
  }

  .team-right-column {
    margin-right: 8vh;
  }

  .team-3 {
    margin-top: 5vh;
  }

  .tippdoktor-logo {
    margin-top: 8vh;
  }

}

@media(max-width: 630px) {

  .why-us {
    margin-left: 13vh;
    margin-right: 13vh;
  }


  .team-left-column {
    margin-left: 14vh;
  }

  .team-right-column {
    margin-right: 10vh;
  }

  .team-3 {
    margin-top: 5vh;
  }

  .team-about {
    margin-left: 14vh;
    margin-right: 14vh;
  }

}

@media(max-width: 550px) {

  .home-image {
    width: 550px;
  }

  .why-us {
    margin-left: 17vh;
    margin-right: 17vh;
  }

  .team-left-column {
    margin-left: 18vh;
  }

  .team-right-column {
    margin-right: 14vh;
  }

  .tippdoktor-logo {
    margin-top: 9vh;
  }

  .tipprofesszor-logo {
    margin-top: 10vh;
  }

  .team-about {
    margin-left: 18vh;
    margin-right: 18vh;
  }

  .tippdoktor-logo {
    margin-top: 16vh;
  }

}

@media(max-width: 520px) {

  .home-heading {
    font-size: 44px;
  }

  .home-image {
    width: 500px;
  }

  .home-hero {
    padding-top: 20vh;
  }

}

@media(max-width: 500px) {

  .home-section {
    height: 140vh;
  }

  .home-caption {
    font-size: 18px;
  }

  .home-heading {
    align-self: center;
  }

  .home-caption {
    align-self: center;
  }

  .home-image {
    margin: 0;
  }

  .home-hero {
    justify-content: center;
    padding-top: 0;
  }

  .why-us {
    margin-left: 0;
    margin-right: 0;
  }

  .home-main {
    width: auto;
  }

  .home-header {
    width: 449px;
    align-items: flex-start;
  }

  .home-image {
    width: 480px;
  }

  .home-paragraph {
    width: 100%;
    height: 100%;
  }

  .home-text036 {
    font-size: 22;
  }

  .home-text040 {
    font-size: 22;
  }

  .home-text041 {
    font-size: 24px;
  }

  .home-text044 {
    font-size: 22;
  }

  .home-text045 {
    font-size: 24px;
  }

  .home-section03 {
    height: 840px;
    padding-top: 164px;
    padding-bottom: 130px;
  }

  .home-content1 {
    height: auto;
  }

  .home-header01 {
    width: 383px;
    padding-left: 5vh;
    padding-right: 5vh;
    height: 500px;
  }

  .home-image1 {
    width: 479px;
    height: 870px;
    align-self: center;
  }

  .home-pricing {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading02 {
    font-size: 32px;
    text-align: center;
  }

  .home-paragraph1 {
    font-size: 16px;
  }

  .home-buy3 {
    height: 100%;
  }

  .home-plan {
    width: 100%;
    height: 100%;
  }

  .home-image3 {
    width: 0;
  }

  .home-main1 {
    width: 70%;
  }

  .home-section08 {
    width: 100%;
  }

  .home-paragraph2 {
    width: 100%;
    height: 100%;
  }

  .home-text096 {
    margin-top: 50px;
  }

  .home-paragraph3 {
    width: 100%;
    height: 100%;
  }

  .home-textinput {
    flex: 1;
  }

  .home-text180 {
    color: rgb(12, 16, 12);
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
  }

  .home-text181 {
    color: rgb(12, 16, 12);
    display: none;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
  }

  .team-left-column {
    margin-left: 0;
    font-size: 14px;
  }

  .team-right-column {
    margin-left: 0;
    margin-right: 0;
  }

  .tippdoktor-logo {
    width: 200px;
    height: 200px;
    padding-right: 2vh;
    margin-top: 9vh;
  }

  .tipprofesszor-logo {
    width: 200px;
    height: 200px;
    padding-right: 2vh;
    margin-top: 10vh;
  }

  .team-about {
    margin-left: 0;
    margin-right: 0;
  }

  .team-2 {
    width: 30vh;
  }

  .team-3 {
    width: 30vh;
  }

}

@media(max-width: 450px) {

  .home-header {
    width: 449px;
    align-items: flex-start;
  }

  .home-caption {
    width: 450px;
  }

  .home-image {
    padding-right: 2vh;
    padding-left: 3vh;

  }

  .home-buttons {
    font-size: 12px;
  }

  .home-heading {
    align-self: center;
  }

  .home-section01 {
    padding: 30px;
  }

  .home-paragraph {
    width: 100%;
    height: 100%;
  }

  .home-section03 {
    background-color: #29292900;
  }

  .home-text036 {
    font-size: 22;
  }

  .home-text040 {
    font-size: 22;
  }

  .home-text041 {
    font-size: 24px;
  }

  .home-text044 {
    font-size: 22;
  }

  .home-text045 {
    font-size: 24px;
  }

  .home-section03 {
    height: 840px;
    padding-top: 164px;
    padding-bottom: 130px;
  }

  .home-content1 {
    height: auto;
  }

  .home-image1 {
    width: 479px;
    height: 870px;
    align-self: center;
  }

  .home-pricing {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .home-heading02 {
    font-size: 32px;
    text-align: center;
  }

  .home-paragraph1 {
    font-size: 16px;
  }

  .home-buy3 {
    height: 100%;
  }

  .home-plan {
    width: 100%;
    height: 100%;
  }

  .home-image3 {
    width: 0;
  }

  .home-main1 {
    width: 70%;
  }

  .home-section08 {
    width: 100%;
  }

  .home-paragraph2 {
    width: 100%;
    height: 100%;
  }

  .home-text096 {
    margin-top: 50px;
  }

  .home-paragraph3 {
    width: 100%;
    height: 100%;
  }

  .home-textinput {
    flex: 1;
  }

  .home-text180 {
    color: rgb(12, 16, 12);
    display: flex;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
    white-space: nowrap;
  }

  .home-text181 {
    color: rgb(12, 16, 12);
    display: none;
    font-size: 24px;
    font-style: normal;
    font-family: Inter;
    font-weight: 700;
    line-height: 24px;
  }

  .home-text096 {
    height: 0px;
    margin-top: 50px;
  }

  .section-head {
    line-height: 45px;
    text-align: center;
  }

  .tippdoktor-logo {
    padding-left: 0vh;
    margin-right: 1vh;
    margin-top: 12.5vh;
  }

  .tipprofesszor-logo {
    padding-left: 0vh;
    margin-right: 1vh
  }

  .team-left-column {
    width: 25vh;
  }

  .team-2 {
    width: 25vh;
  }

  .home-section03 {
    padding-top: 20vh;
    padding-bottom: 20vh;
    height: 20vh;
  }

}

@media(max-width: 414px) {

  .home-image {
    width: 110%;
  }

  .home-heading {
    font-size: 34px;
  }

  .home-caption {
    width: 350px;
  }

  .home-buttons {
    display: inline;
  }

  .home-section {
    width: 95%;
    align-self: center;
    align-items: center;
  }

  .team-left-column {
    width: 20vh;
  }

  .team-2 {
    width: 23vh;
  }

  .team-3 {
    width: 23vh;
  }

  .tipprofesszor-logo {
    margin-top: 16vh;
  }

  .tippdoktor-logo {
    margin-top: 12vh;
  }

  .home-plan {

  }

  .home-plan0 {
    width: auto;
    height: auto;
  }

  .home-plan1 {
    width: auto;
    height: auto;
  }

  .home-plan2 {
    width: auto;
    height: auto;
  }

  .home-text076 {
    padding-bottom: 0vh;
  }

  .home-text096 {
    padding-bottom: 5vh;
    padding-top: 5vh;
  }

}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.linethrough {
  text-decoration: line-through;
}

@media (max-width: 1600px) {
  dialog {
    width: 80%;
  }
}

@media (max-width: 1200px) {
  dialog {
    width: 85%;
  }
}

@media (max-width: 992px) {
  dialog {
    width: 90%;
  }
}

@media (max-width: 768px) {
  dialog {
    width: 95%;
  }
}

@media (max-width: 576px) {
  dialog {
    width: 100%;
  }
}

@media (max-width: 480px) {
  dialog {
    width: 100%;
  }

  #dialog9 {
    width: 100%;
    font-size: 8px;
  }
}

@media (max-width: 360px) {
  dialog {
    width: 100%;
  }
}