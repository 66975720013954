.card-container {
  display: flex;
  justify-content: space-around; /* or space-between, depending on your preference */
  flex-wrap: wrap; /* Allow cards to wrap to the next line */
  gap: 5vh;
}

figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 375px;
  min-width: 375px;
  height: 667px;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}

figure:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

figure:hover h1 {
  opacity: 0;
  transform: scale(0.7);
}

figure:hover img {
  transform: scale(1.25);
}

figure:hover figcaption {
  bottom: 0;
}

figure h1 {
  color: #E4E40C;
  position: absolute;
  top: 50px;
  left: 20px;
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  opacity: 1;
  transform: scale(1);
  transition: 0.25s ease;
  z-index: 999;
}

figure img {
  height: 60%;
  width: 100%;
  transition: 0.25s;
}

figure figcaption {
  position: absolute;
  bottom: -34%;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 30px;
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  color: white;
  line-height: 1;
  transition: 0.25s;
}

figure figcaption h3 {
  margin: 0 0 20px;
  padding: 0;
}

figure figcaption p {
  font-size: 14px;
  line-height: 1.15;
}

figure figcaption button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0 0;
  padding: 10px 30px;
  background-color: #1abc9c;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (max-width: 1300px) {
  .card-container {
    justify-content: center;
  }
}