/*Section used for centering process*/
.accordion-section {
  width: 100%;
  height: 100vh;
  background-color: #00000000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.container {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.accordion-item {
  background-color: #00000000;
  border-radius: .4rem;
}

.accordion-item hr {
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.accordion-link {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, .8);
  text-decoration: none;
  background-color: #00000000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
}

.accordion-link h3 {
  font-weight: 500;
  font-size: 20px;
}

.accordion-link i {
  color: #b3ff00;
  padding: .5rem;

}

.accordion-link ul {
  display: flex;
  align-items: flex-end;
  list-style-type: none;
  margin-left: 25px;
}

.accordion-link li {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.4);
  padding: 0 0 1px 5px;
}

.accordion-link div {
  display: flex;
}

.accordion-link .ion-md-arrow-down {
  display: none;
}

.answer {
  max-height: 0;
  overflow: hidden;
  position: relative;
  background-color: #00000000;
  transition: max-height 650ms;
}

.answer p {
  color: #fff;
  font-size: 15px;
  padding: 2rem;

}

.accordion-item:target .answer {
  max-height: 20rem;
}

.accordion-item:target .accordion-link .ion-md-arrow-forward {
  display: none
}

.accordion-item:target .accordion-link .ion-md-arrow-down {
  display: block;
}